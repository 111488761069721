import { takeLatest, takeEvery, call, put, all, select } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Store from 'store';

import * as Actions from './actions';
import * as Api from './api';
import * as Constants from './constants';
import * as Mappers from './mappers';
import * as Types from './types';

export function* Start(action: ReturnType<typeof Actions.Start.request>) {
  const { documentId, applicantType, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.Start, { documentId, applicantType });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.Start.success({ process, info, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process, info });
    }
    yield put(push(`/application/${process.applicationId}`));
    // yield put();
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Start.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* StartByRegister(action: ReturnType<typeof Actions.StartByRegister.request>) {
  const { registerId, type, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.StartByRegister, { registerId, type });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.StartByRegister.success({ process, info, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process, info });
    }
    yield put(push(`/application/${process.applicationId}`));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.StartByRegister.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* StartByBranch(action: ReturnType<typeof Actions.StartByBranch.request>) {
  const { registerId, type, id, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.StartByBranch, { registerId, type, id });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.StartByBranch.success({ process, info, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process, info });
    }
    yield put(push(`/application/${process.applicationId}`));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.StartByBranch.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* StartByBranchSingle(action: ReturnType<typeof Actions.StartByBranchSingle.request>) {
  const { registerId, type, id, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.StartByBranchSingle, {
      id,
      registerId,
      type
    });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.StartByBranchSingle.success({ process, info, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process, info });
    }
    yield put(push(`/application/${process.applicationId}`));
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.StartByBranchSingle.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Update(action: ReturnType<typeof Actions.Update.request>) {
  const { applicationId, stepId, position, values, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.Update, {
      applicationId,
      stepId,
      position,
      values
    });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const fieldValues = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.Update.success({ process, values: fieldValues }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Update.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Back(action: ReturnType<typeof Actions.Back.request>) {
  const { applicationId, stepId, position, changePosition, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.Back, {
      applicationId,
      stepId,
      position,
      changePosition
    });
    const process = Mappers.process(data);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.Back.success({ process, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Back.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Resume(action: ReturnType<typeof Actions.Resume.request>) {
  const { applicationId, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Start.Response> = yield call(Api.Resume, { applicationId });
    const process = Mappers.process(data);
    const { data: infoData }: AxiosResponse<{ data: Types.IApi.Info.Response }> = yield call(Api.Info, {
      applicationId,
      documentId: process.documentId
    });
    const info = Mappers.info(get(infoData, 'data') || {});
    const values = Mappers.values(get(data, 'data.values') || [], info);
    yield put(Actions.Resume.success({ process, info, values }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { process, info });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Resume.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Cancel(action: ReturnType<typeof Actions.Cancel.request>) {
  const { applicationId, callback } = action.payload;
  try {
    const { data } = yield call(Api.Cancel, { applicationId });
    yield put(Actions.Cancel.success());
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { message: get(data, 'message') });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    yield put(Actions.Cancel.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Specialization(action: ReturnType<typeof Actions.Specialization.request>) {
  const { documentId, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Specialization.Response> = yield call(Api.Specialization, { documentId });
    const specialization = (get(data, 'data.specializations') || []).map(item => Mappers.specialization(item));
    yield put(Actions.Specialization.success({ specialization }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { specialization });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Specialization.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* Requirements(action: ReturnType<typeof Actions.Requirements.request>) {
  const { documentId, specializationIds, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Specialization.Response> = yield call(Api.Requirements, {
      documentId,
      specializationIds
    });
    const requirements = orderBy(get(data, 'data.requirements') || [], ['position', 'id'], ['asc', 'asc']).map(item =>
      Mappers.requirements(item)
    );
    yield put(Actions.Requirements.success({ requirements }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { requirements });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.status_message;
    yield put(Actions.Requirements.failure({ error }));
    if (callback?.onError) {
      yield call(callback.onError, { error: errorMessage });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export function* ActionEvent(action: ReturnType<typeof Actions.ActionEvent.request>) {
  const { values, callback } = action.payload;
  try {
    const { data }: AxiosResponse<Types.IApi.Specialization.Response> = yield call(Api.ActionEvent, values);
    const process = yield select((state: Store.Types.IState) => state.process.process);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const { fields, values: fieldsValues } = Mappers.actionEventList(
      get(data, 'data.field_events') || [],
      process,
      info
    );
    yield put(Actions.ActionEvent.success({ fields, values: fieldsValues }));
    if (callback?.onSuccess) {
      yield call(callback.onSuccess, { fields, values: fieldsValues });
    }
  } catch (error) {
    const errorResponse = error as AxiosError;
    const errorMessage = errorResponse?.response?.data?.message;
    const process = yield select((state: Store.Types.IState) => state.process.process);
    const info = yield select((state: Store.Types.IState) => state.process.info);
    const { fields, values: fieldsValues } = Mappers.actionEventList(
      get(errorResponse, 'response.data.data.field_events') || [],
      process,
      info
    );
    yield put(Actions.ActionEvent.success({ fields, values: fieldsValues }));
    if (callback?.onError) {
      yield call(callback.onError, { message: errorMessage, fields, values: fieldsValues });
    }
  } finally {
    if (callback?.onFinally) {
      yield call(callback.onFinally);
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(Constants.START.REQUEST, Start),
    takeLatest(Constants.START_BY_REGISTER.REQUEST, StartByRegister),
    takeLatest(Constants.START_BY_BRANCH.REQUEST, StartByBranch),
    takeLatest(Constants.START_BY_BRANCH_SINGLE.REQUEST, StartByBranchSingle),
    takeLatest(Constants.UPDATE.REQUEST, Update),
    takeLatest(Constants.BACK.REQUEST, Back),
    takeLatest(Constants.RESUME.REQUEST, Resume),
    takeLatest(Constants.CANCEL.REQUEST, Cancel),
    takeLatest(Constants.SPECIALIZATION.REQUEST, Specialization),
    takeLatest(Constants.REQUIREMENTS.REQUEST, Requirements),
    takeEvery(Constants.ACTION_EVENT.REQUEST, ActionEvent)
  ]);
}
