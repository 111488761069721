import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import get from 'lodash/get';

import * as AuthModule from 'modules/auth';
import * as ProcessModule from 'modules/process';
import * as SystemModule from 'modules/system';

import * as Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { Nav } from 'components/Modal';

import classes from './ApplicantType.module.scss';

interface IProps {
  documentId: number;
  close: () => void;
}


const ApplicantType: React.FC<IProps> = ({ documentId, close }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isAuthenticated } = AuthModule.Hooks.useAuth();
  const { language } = SystemModule.Hooks.useLanguage();

  const [isLoading, setLoading] = useState(false);

  const { methods } = ProcessModule.Hooks.useProcess({
    onError: (error) => {
      setLoading(false);
      const message = get(error, `message.title[${language}]`) || '';
      const type = get(error, `message.type`) || '';
      !!message && (type === 'INFO' ? toast(message) : toast.error(message));
    }
  });

  const onStart = (applicantType: ProcessModule.Constants.APPLICANT_TYPE) => {
    if(isAuthenticated){
      methods.start(documentId, applicantType);
      setLoading(true);
    } else {
      history.push('/auth');
    }
  };

  const APPLICANT_TYPE = ProcessModule.Constants.APPLICANT_TYPE;
  const items = [
    { title: t(APPLICANT_TYPE.INDIVIDUAL), type: APPLICANT_TYPE.FOREIGN_INDIVIDUAL },
    { title: t(APPLICANT_TYPE.LEGAL_ENTITY), type: APPLICANT_TYPE.FOREIGN_LEGAL_ENTITY }
  ];

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.spinner}>
          <Spinner/>
        </div>
      )}
      <Nav showClose onClose={close}/>
      <div className={classes.title}>{t('select_the_type_of_licensee')}</div>
      <div className={classes.list}>
        {items.map(item => (
          <div key={item.type} className={classes.listItem} onClick={() => onStart(item.type)}>
            <div className={classes.listItemTitle}>{item.title}</div>
            <div className={classes.listItemIcon}>
              <Icon.System.Right/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicantType;
