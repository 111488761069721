import React, { useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';
import cx from 'classnames';
import moment from 'moment';
import { toast } from 'react-hot-toast';

// import { http } from 'services';
// import config from 'config';

import * as RegistryModule from 'modules/registry';
import * as SystemModule from 'modules/system';
import * as CertificateModule from 'modules/certificate';

import { Nav } from 'components/Modal';
import Type from 'components/Type';
import InfoBlock from 'components/InfoBlock';
import * as Details from 'components/Details';
import Spinner from 'components/Spinner';
import Status from 'components/Status';
// import Button from 'components/Button';
import List, { Item as ListItem } from 'components/List';
// import * as Icon from 'components/Icon';
// import * as Certificate from 'components/Certificate';
import BranchesList from './components/BranchesList';
import classes from './RegistryView.module.scss';
import DocumentGenerator from 'components/DocumentGenerator';

interface IProps {
  id: string;
  close: () => void;
  onSelect: (id) => void;
}

enum TAB {
  INFO = 1,
  DOCUMENT = 2,
  HISTORY = 3,
  BRANCHES = 4,
  ANNUAL_FEE = 5
}

const View: React.FC<IProps> = ({ id, close, onSelect }) => {
  const { t, i18n } = useTranslation();
  const { language } = SystemModule.Hooks.useLanguage();
  const [activeTabId, setActiveTabId] = useState(TAB.INFO);
  // const [isDownloading, setDownloading] = useState(false);
  // const [isPrinting, setPrinting] = useState(false);

  const { item, isFetched, isLoading } = RegistryModule.Hooks.useSingle({
    id,
    onError: () => {
      close();
      toast.error(`${t('internal_server_error')}`);
    },
    onFinally: () => setActiveTabId(TAB.INFO)
  });

  if (!item.id || isLoading || !isFetched) {
    return (
      <div className={classes.spinner}>
        <Spinner />
      </div>
    );
  }

  const tabs = [
    { id: TAB.INFO, title: t('detailed_info'), isVisible: true },
    { id: TAB.DOCUMENT, title: t('document'), isVisible: !item.isArchive || item.history.length },
    { id: TAB.HISTORY, title: t('history'), isVisible: item.isActive && !!get(item, 'history.length') },
    { id: TAB.BRANCHES, title: t('branches'), isVisible: item.branch }
    // { id: TAB.ANNUAL_FEE, title: t('annual_fee'), isVisible: true },
  ];
  const tabsFiltered = tabs.filter(tab => tab.isVisible);

  const STATUS = CertificateModule.Constants.STATUS;
  const status = {
    [STATUS.ACTIVE]: 'green' as const,
    [STATUS.SUSPENDED]: 'orange' as const,
    [STATUS.SUSPEND_PROCESS]: 'orange' as const,
    [STATUS.NOT_PAID]: 'orange' as const,
    [STATUS.EXPIRED]: 'red' as const,
    [STATUS.CANCELED]: 'red' as const,
    [STATUS.CHANGED]: 'orange' as const
  };

  const feeDateBeginFormatted = moment(item.feeDate.begin, 'MM.YYYY').format('MMMM, YYYY');
  const feeDateEndFormatted = moment(item.feeDate.end, 'MM.YYYY').format('MMMM, YYYY');
  const suspentionBeginFormatted = moment(item.suspension.begin, 'MM.YYYY').format('MMMM, YYYY');
  const suspentionEndFormatted = moment(item.suspension.end, 'MM.YYYY').format('MMMM, YYYY');

  return (
    <div className={classes.wrapper}>
      <Nav showClose onClose={close} />
      <div className={classes.headerMobileTitle}>{t('registry')}</div>
      <div className={classes.header}>
        <div className={classes.headerImage}>
          <img src={get(item, 'category.image')} alt={get(item, `category.title[${language}]`)} />
        </div>
        <div className={classes.headerContent}>
          <div className={classes.tags}>
            <Type variant="blue" className="m-r-12">
              {get(item, `type.title[${language}]`)}
            </Type>
            <Type variant="dark-gray">{item.name}</Type>
            {item.isArchive && (
              <Status variant="danger" className="m-l-12">
                {t('archive')}
              </Status>
            )}
          </div>
          <div className={classes.category}>{get(item, `category.title[${language}]`)}</div>
          <div className={classes.title}>{get(item, `document[${language}]`)}</div>
          <div className={classes.info}>
            <div className={classes.infoInner}>
              <InfoBlock
                variant={status[item.status.key] || 'white'}
                title={t('Status')}
                children={get(item, `status.title[${language}]`)}
                className="m-r-12"
              />
              <InfoBlock variant="white" title={t('License_number')} children={item.number} className="m-r-12" />
              <InfoBlock
                variant="white"
                title={t('date_of_issue')}
                children={item.registration.date}
                className="m-r-12"
              />
              {item.type.key !== 'NOTIFICATION' && (
                <InfoBlock
                  variant="white"
                  title={t('Valid_until')}
                  children={item.expires ? item.expires : t('limitless')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {tabsFiltered.length > 1 && (
        <div className={classes.tabWrapper}>
          <div className={classes.tabWrapperInner}>
            {tabsFiltered.map(tab => (
              <div
                key={tab.id}
                className={cx(classes.tabItem, tab.id === activeTabId && classes.tabItemActive)}
                onClick={() => setActiveTabId(tab.id)}
              >
                {tab.title}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={classes.content}>
        {activeTabId === TAB.INFO && (
          <>
            <div className={classes.listTitle}>{t('detailed_info')}</div>
            <Details.List className="m-b-24">
              <Details.Item title={t('Status')}>{get(item, `status.title[${language}]`)}</Details.Item>
              <Details.Item title={t('name_of_licensee')}>{item.name}</Details.Item>
              {!item.isArchive && (
                <Details.Item title={t('Address')}>
                  {[get(item, `region[${language}]`), get(item, `subRegion[${language}]`), get(item, 'address')]
                    .filter(address => !!address)
                    .join(', ')}
                </Details.Item>
              )}
              <Details.Item title={t('inn_of_licensee')}>{item.tin}</Details.Item>
              <Details.Item title={t('License_number')}>{item.number}</Details.Item>
              {!item.isArchive && <Details.Item title={t('registry_number')}>{item.registration.number}</Details.Item>}
              <Details.Item title={t('date_of_issue')}>{item.registration.date}</Details.Item>
              {item.selected_category[i18n.language] && (
                <Details.Item title={t('selected_category')}>{item.selected_category[i18n.language]}</Details.Item>
              )}
              {item.type.key !== 'NOTIFICATION' && (
                <Details.Item title={t('Valid_until')}>{item.expires ? item.expires : t('limitless')}</Details.Item>
              )}
              {!!item.activityAddresses && !!item.activityAddresses.length && (
                <Details.Item title={t('Activity_addresses')}>
                  {item.activityAddresses.map(activityAddress => (
                    <>
                      {get(activityAddress, `[${language}]`)} <br />
                    </>
                  ))}
                </Details.Item>
              )}
              {!!item.feeDate.begin && !!item.feeDate.end && (
                <Details.Item title={t('fees_start_end_date')}>
                  {item.feeDate.begin ? `${feeDateBeginFormatted} ${item.feeDate.end ? ' - ' : ''}` : ''}
                  {item.feeDate.end ? feeDateEndFormatted : ''}
                </Details.Item>
              )}
              {item.status.key === STATUS.SUSPEND_PROCESS && (
                <Details.Item title={t('suspension_start_end_date')}>
                  {item.suspension.begin ? `${suspentionBeginFormatted} ${item.suspension.end ? ' - ' : ''}` : ''}
                  {item.suspension.end ? suspentionEndFormatted : ''}
                </Details.Item>
              )}
            </Details.List>
            {!!item.specializations && !!item.specializations.length && (
              <List title={t('Specializations')} className={classes.list}>
                {item.specializations.map((item, i) => {
                  const title = get(item, `[name][${language}]`);
                  const description = get(item, `[description][${language}]`);
                  return (
                    <ListItem number={i + 1}>
                      {!!title && <p>{HtmlParser(title)}</p>}
                      {!!description && <p>{HtmlParser(description)}</p>}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </>
        )}

        {activeTabId === TAB.DOCUMENT && <DocumentGenerator uuid={id} />}

        {activeTabId === TAB.BRANCHES && <BranchesList id={id} />}

        {/* {activeTabId === TAB.ANNUAL_FEE && <BranchesList id={id} />} */}

        {activeTabId === TAB.HISTORY && (
          <div className={classes.history}>
            <div className={classes.historyHeader}>
              <div className={classes.historyCell}>{t('Status')}</div>
              <div className={classes.historyCell}>{t('Document_number')}</div>
              <div className={classes.historyCell}>{t('registry_number')}</div>
              <div className={classes.historyCell}>{t('Validity_period')}</div>
            </div>
            <div className={classes.historyBody}>
              {item.history.map(historyItem => (
                <div
                  key={historyItem.id}
                  className={classes.historyRow}
                  onClick={() => historyItem.id && onSelect(historyItem.id)}
                >
                  <div className={classes.historyCell}>
                    <Status variant={historyItem.isActive ? 'success' : 'danger'} block>
                      {get(historyItem, `status.title[${language}]`)}
                    </Status>
                  </div>
                  <div className={classes.historyCell}>{get(historyItem, 'number')}</div>
                  <div className={classes.historyCell}>{get(historyItem, 'register_number')}</div>
                  <div className={classes.historyCell}>
                    {get(historyItem, 'register_date')
                      ? `${t('date_from', { value: get(historyItem, 'register_date') })}`
                      : ''}
                    {get(item, 'type.key') !== 'NOTIFICATION' && get(historyItem, 'expire_date') && (
                      <>
                        <br />
                        {t('date_to', { value: get(historyItem, 'expire_date') })}
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;
