import React, { useState } from 'react'
import * as Table from 'components/Table';
import classes from "../RegistryView.module.scss"
import { useTranslation } from 'react-i18next';
import * as Icon from 'components/Icon';
import Button from 'components/Button';
import { get } from 'lodash';
import useOpenList from 'modules/branches/hooks/useOpenList';
import Pagination from 'components/Pagination';
import Spinner from 'components/Spinner';
import Modal from 'components/Modal';
import WorkersModal from './Workers';

const BranchesModal = ({ id }: { id: string }) => {
    const { t, i18n } = useTranslation()
    const [page, setPage] = useState<number>(0)
    const [workers, setWorkers] = useState<string>("")
    const { items, meta, isLoaded, isFetched } = useOpenList({
        uuid: id,
        params: {
            limit: 10,
            page
        }
    })

    const pageChange = (current) => {
        setPage(Number(current))
    };


    if (!isLoaded && !isFetched) {
        return <Spinner />
    }

    return (
        <div className={classes.branches}>
            {
                !!items.length ? (
                    <>
                        <Table.Table className={classes.table}>
                            <Table.Header>
                                <Table.HeaderCell>{t('region')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('subregion')}</Table.HeaderCell>
                                <Table.HeaderCell>{t('address')}</Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Header>
                            <Table.Body>
                                {
                                    items?.map(item => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>
                                                {item.region[i18n.language]}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.subRegion[i18n.language]}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.village[i18n.language] && `${item.village[i18n.language]}, `}
                                                {item.address}
                                            </Table.Cell>
                                            <Table.ActionCell>
                                                <Button variant="grey" prefixIcon={<Icon.System.DocReview />} onClick={() => setWorkers(item.id.toString())} />
                                            </Table.ActionCell>
                                        </Table.Row>
                                    ))
                                }
                            </Table.Body>
                        </Table.Table>
                        <Pagination total={get(meta, 'total')} current={get(meta, 'current')} onChange={(val => pageChange(val))} />
                    </>) : (
                    <h4 className={classes.empty}>
                        {t('branches_empty_content_not_branch')}
                    </h4>
                )}


            <Modal
                isOpen={!!workers}
                close={() => setWorkers('')}>
                <WorkersModal close={() => setWorkers('')} id={workers} uuid={id} />
            </Modal>
        </div>
    )
}

export default BranchesModal