import React, { useMemo, useState } from 'react';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import HtmlParser from 'react-html-parser';
import cx from 'classnames';
import moment from 'moment';
import Select from 'react-select';
import * as Icon from 'components/Icon';
import * as Helpers from 'helpers';
import * as CertificateModule from 'modules/certificate';

import Status from 'components/Status';
import TextBlock from 'components/TextBlock';
import * as PaymentModule from 'modules/payment';

import classes from '../CertificateVew.module.scss';
import Button from 'components/Button';
import Modal, { Nav } from 'components/Modal';
import { http } from 'services';
import toast from 'react-hot-toast';
import Invoice from 'components/Invoice';
import DownloadInvoice from 'components/ImageAuth/Invoice';
import Empty from 'components/Empty';
import * as Mappers from 'modules/payment/mappers.ts';
import * as Types from 'modules/payment/types';

interface IProps {
  item: CertificateModule.Types.IEntity.Certificate;
  payments: CertificateModule.Types.IEntity.Payment[];
  hasUsageFees: boolean;
  fees: CertificateModule.Types.IEntity.Fee[];
  feeDateEnd: string;
  handleFetch?: () => void;
}

enum TAB {
  FEES = 1,
  APPLICATION = 2
}

const Payments: React.FC<IProps> = ({ item, payments, hasUsageFees, fees, feeDateEnd, handleFetch }) => {
  const { t } = useTranslation();

  const hasMonth = useMemo(() => {
    const checkHasFalse = item.specializations.map(item => [4634, 4635, 4636, 4637].includes(item.id));
    if (checkHasFalse.includes(false)) {
      return false;
    }

    return true;
  }, []);

  const options = [
    ...(hasMonth
      ? [
          {
            value: 101,
            label: `3 ${t('month')}`
          },
          {
            value: 102,
            label: `6 ${t('month')}`
          },
          {
            value: 103,
            label: `9 ${t('month')}`
          }
        ]
      : []),
    { value: 1, label: `1 ${t('year')}` },
    { value: 2, label: `2 ${t('year')}` },
    { value: 3, label: `3 ${t('year')}` },
    { value: 4, label: `4 ${t('year')}` },
    { value: 5, label: `5 ${t('year')}` }
  ];

  const initialTab = hasUsageFees && fees.length && !payments.length ? TAB.FEES : TAB.APPLICATION;

  const [activeTabId, setActiveTabId] = useState(initialTab);
  const [annualFee, setAnnualFee] = useState(false);
  const [invoice, setInvoice] = useState<Types.IEntity.Invoice>(Mappers.invoice({}));
  const [loading, setLoading] = useState(false);
  const [annualDate, setAnnualDate] = useState(null);
  const [isOpenInvoice, setOpenInvoice] = useState(false);
  const [isBanking, setBanking] = useState(false);
  const [isCashDesk, setCashDesk] = useState(false);
  const [isPaying, setPaying] = useState(false);

  const tabs = [
    { id: TAB.FEES, title: t('mandatory_contributions') },
    { id: TAB.APPLICATION, title: t('payments_for_application') }
  ];

  const status = {
    PAID: t('invoice_payment_paid'),
    OPEN: t('invoice_payment_open'),
    EXPIRED: t('invoice_payment_expired'),
    CANCELED: t('invoice_payment_canceled')
  };

  const feeDateFormatted = moment(feeDateEnd, 'MM.YYYY').format('MMMM, YYYY');

  const handleAnnualDate = async () => {
    if (annualDate) {
      setLoading(true);
      await http.request
        .post(
          '/payment/fees/annual',
          {},
          {
            params: {
              register_id: item.id,
              quantity: annualDate
            }
          }
        )
        .then(({ data }) => {
          const invoice = Mappers.invoice(get(data, 'data'));
          setInvoice(invoice);
          setOpenInvoice(true);
        })
        .catch(error => toast.error(`${t(error.response.data.status_message)}`))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {/* {(hasUsageFees && !!fees.length && !!payments.length) && (
       
      )} */}
      <div className={cx(classes.tabWrapper, classes.tabSecondary)}>
        <div className={classes.tabWrapperInner}>
          {tabs.map(tab => (
            <div
              key={tab.id}
              className={cx(classes.tabItem, tab.id === activeTabId && classes.tabItemActive)}
              onClick={() => setActiveTabId(tab.id)}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>

      {[4603, 4291, 3957, 3417].includes(item.document_id) && (
        <div className={classes.annual}>
          <Button onClick={() => setAnnualFee(true)}>{t('annual_fee')}</Button>
        </div>
      )}
      {activeTabId === TAB.FEES && (
        <>
          {feeDateEnd && feeDateFormatted && (
            <TextBlock variant="BLUE" className={classes.feeInfo}>
              {HtmlParser(t('paid_until', { date: feeDateFormatted }))}
            </TextBlock>
          )}
          <div className={classes.table}>
            <div className={classes.tableHeader}>
              <div className={classes.tableCell}>{t('Status')}</div>
              <div className={classes.tableCell}>{t('invoice_number')}</div>
              <div className={classes.tableCell}>{t('Payment_amount')}</div>
              <div className={classes.tableCell}>{t('for_period')}</div>
              <div className={classes.tableCell}>{t('Payment_date')}</div>
            </div>
            <div className={classes.tableBody}>
              {!!fees.length ? (
                fees.map(payment => {
                  let itemStatus = 'danger';
                  if (payment.status === 'PAID') {
                    itemStatus = 'success';
                  } else if (payment.status === 'OPEN') {
                    itemStatus = 'warning';
                  }

                  return (
                    <div key={payment.number} className={classes.tableRow}>
                      <div className={classes.tableCell}>
                        <Status variant={itemStatus as any} block>
                          {get(status, payment.status)}
                        </Status>
                      </div>
                      <div className={classes.tableCell}>{get(payment, 'number')}</div>
                      <div className={classes.tableCell}>
                        {t('license_price', { value: Helpers.currency.format(get(payment, 'amount')) })}
                      </div>
                      <div className={classes.tableCell}>
                        {get(payment, 'begin')}-{get(payment, 'end')}
                      </div>
                      <div className={classes.tableCell}>{get(payment, 'date')}</div>
                    </div>
                  );
                })
              ) : (
                <Empty hasButton={false} title="you_dont_have_any_invoices" />
              )}
            </div>
          </div>
        </>
      )}
      {activeTabId === TAB.APPLICATION && (
        <div className={classes.table}>
          <div className={classes.tableHeader}>
            <div className={classes.tableCell}>{t('Status')}</div>
            <div className={classes.tableCell}>{t('invoice_number')}</div>
            <div className={classes.tableCell}>{t('Payment_amount')}</div>
            <div className={classes.tableCell}>{t('Payment_date')}</div>
          </div>
          <div className={classes.tableBody}>
            {!!payments.length ? (
              payments.map(payment => {
                let itemStatus = 'danger';
                if (payment.status === 'PAID') {
                  itemStatus = 'success';
                } else if (payment.status === 'OPEN') {
                  itemStatus = 'warning';
                }

                return (
                  <div key={payment.number} className={classes.tableRow}>
                    <div className={classes.tableCell}>
                      <Status variant={itemStatus as any} block>
                        {get(status, payment.status)}
                      </Status>
                    </div>
                    <div className={classes.tableCell}>{get(payment, 'number')}</div>
                    <div className={classes.tableCell}>
                      {t('license_price', { value: Helpers.currency.format(get(payment, 'amount')) })}
                    </div>
                    <div className={classes.tableCell}>{get(payment, 'date')}</div>
                  </div>
                );
              })
            ) : (
              <Empty hasButton={false} title="you_dont_have_any_invoices" />
            )}
          </div>
        </div>
      )}
      <Modal size="small" backdropClose={!loading} isOpen={annualFee} close={() => setAnnualFee(false)}>
        <div className={classes.content}>
          <div className={classes.annualContainer}>
            <Select onChange={e => setAnnualDate(get(e, 'value'))} className={classes.annualInput} options={options} />
            <Button isLoading={loading} disabled={!annualDate} onClick={handleAnnualDate}>
              {t('payment_annual_pay')}
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isOpenInvoice}
        close={() => {
          handleFetch && handleFetch();
          setOpenInvoice(false);
        }}
        width={900}
        variant="white"
      >
        <Nav
          showClose
          onClose={() => {
            handleFetch && handleFetch();
            setOpenInvoice(false);
          }}
          variant="dark"
        />
        <div className={classes.paymentModal}>
          <div className={classes.paymentModalTitle}>{t('pay_with')}:</div>
          <div className={classes.paymentModalButtonList}>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Company />}
              htmlType="button"
              onClick={() => setBanking(true)}
            >
              {t('online_banking')}
            </Button>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Cheque />}
              htmlType="button"
              onClick={() => setCashDesk(true)}
            >
              {t('through_the_bank_cash_desk')}
            </Button>
            <Button
              variant="stroke"
              prefixIcon={<Icon.System.Card />}
              htmlType="button"
              onClick={() => setPaying(true)}
            >
              {t('credit_card')}
            </Button>
          </div>
          <TextBlock className={classes.paymentModalInfo} variant="BLUE">
            {HtmlParser(
              t('payment_for_fee_title', {
                id: item.number,
                begin_date: moment(invoice.begin, 'DD.MM.YYYY').format('MMMM, YYYY'),
                end_date: moment(invoice.end, 'DD.MM.YYYY').format('MMMM, YYYY')
              })
            )}
            {/* <span className={classes.paymentModalInfoChange} onClick={ChangeDate}>{t('change_the_period')}</span> */}
          </TextBlock>
          <DownloadInvoice hasDownload hideInvoice invoiceNumber={invoice.serial} />
          <Invoice
            date={invoice.issue_date}
            amount={t('license_price', { value: Helpers.currency.format(invoice.amount) })}
            invoiceNumber={Helpers.stringMask(invoice.serial || '', '##############')}
            qr={invoice.qr}
            status={invoice.status as any}
            organization={invoice.payee}
            account={Helpers.stringMask(invoice.budget_account || '', '##### ##### ##### ##### #####')}
            bank={{
              name: invoice.bank.name,
              account: Helpers.stringMask(invoice.bank.account || '', '#### #### #### #### ####'),
              code: invoice.bank.mfi
            }}
            details={invoice.details}
          />
        </div>
      </Modal>

      <Modal size="small" variant="white" isOpen={isPaying} close={() => setPaying(false)}>
        <PaymentModule.Components.Form
          registerId={item.id}
          serial={invoice.serial}
          amount={Helpers.currency.format(invoice.amount_online, { precision: 2 })}
          close={() => setPaying(false)}
          onSuccess={() => {}}
        />
      </Modal>

      <Modal size="s-middle" isOpen={isCashDesk} close={() => setCashDesk(false)}>
        <PaymentModule.Components.CashDesk serial={invoice.serial} close={() => setCashDesk(false)} />
      </Modal>

      <Modal size="s-middle" isOpen={isBanking} close={() => setBanking(false)}>
        <PaymentModule.Components.Banking
          mfi={invoice.bank.mfi}
          account={invoice.budget_account}
          bank={{
            name: invoice.bank.name,
            account: invoice.bank.account
          }}
          details={invoice.details}
          amount={`${invoice.amount}`}
          close={() => setBanking(false)}
        />
      </Modal>
    </>
  );
};

export default Payments;
