import get from 'lodash/get';
import orderBy from 'lodash/orderBy';

import * as Types from './types';

export const application = (item: Types.IApi.Application.Response): Types.IEntity.Application => ({
  id: item.id || 0,
  category: {
    title: langStrings(get(item, 'category.title')),
    image: get(item, 'category.icon_id') || '',
    gradient: {
      start: get(item, 'category.gradient_start_color') || '',
      end: get(item, 'category.gradient_end_color') || ''
    }
  },
  branchType: get(item, 'branch_type') || '',
  document: langStrings(get(item, 'document')),
  type: langStrings(get(item, 'type')),
  step: {
    total: get(item, 'step.count') || 0,
    current: get(item, 'step.position') || 0,
    title: langStrings(get(item, 'step.title'))
  },
  register: {
    date: get(item, 'registration_date') || '',
    number: get(item, 'registration_number') || ''
  },
  status: {
    key: get(item, 'status.key') || '',
    color: get(item, 'status.background_color') || '',
    title: langStrings(get(item, 'status.title'))
  },
  applicationType: {
    key: get(item, 'application_type.key') || '',
    title: langStrings(get(item, 'application_type.title'))
  },
  applicant: {
    name: get(item, 'applicant_name') || '',
    tin: get(item, 'applicant_tin') || ''
  }
});

export const applicationSingle = (item?: Types.IApi.ApplicationSingle.Response): Types.IEntity.ApplicationSingle => ({
  id: get(item, 'id') || 0,
  register_id: get(item, 'register_id') || 0,
  uuid: get(item, 'uuid') || '',
  category: {
    title: langStrings(get(item, 'category.title')),
    image: get(item, 'category.icon_id') || '',
    gradient: {
      start: get(item, 'category.gradient_start_color') || '',
      end: get(item, 'category.gradient_end_color') || ''
    }
  },
  document: langStrings(get(item, 'document')),
  action_log: get(item, 'action_log'),
  documents: get(item, 'documents'),
  type: langStrings(get(item, 'type')),
  step: {
    total: get(item, 'step.count') || 0,
    current: get(item, 'step.position') || 0,
    title: langStrings(get(item, 'step.title'))
  },
  register: {
    date: get(item, 'registration_date') || '',
    number: get(item, 'registration_number') || ''
  },
  status: {
    can: get(item, 'status.can') || '',
    key: get(item, 'status.key') || '',
    color: get(item, 'status.background_color') || '',
    title: langStrings(get(item, 'status.title'))
  },
  applicant: {
    name: get(item, 'applicant_name') || '',
    tin: get(item, 'applicant_tin') || ''
  },
  steps: orderBy((get(item, 'steps') || []).map(s => step(s)), ['position'], ['asc']),
  review_expiry: get(item, 'review_expiry') || '',
  correction_expiry: get(item, 'correction_expiry') || '',
});

export const step = (item: Types.IApi.ApplicationSingle.Step): Types.IEntity.Step => ({
  title: langStrings(get(item, 'title')),
  position: get(item, 'position') || 0,
  groups: orderBy((get(item, 'groups') || []).map(f => group(f)), ['position'], ['asc']),
  type: get(item, 'type')
});

export const group = (item: Types.IApi.ApplicationSingle.Group): Types.IEntity.Group => ({
  title: langStrings(get(item, 'title')),
  position: get(item, 'position') || 0,
  isDuplicate: !!get(item, 'group_duplicate'),
  fields: orderBy((get(item, 'fields') || []).map(f => field(f)), ['position'], ['asc'])
});

export const field = (item: Types.IApi.ApplicationSingle.Field): Types.IEntity.Field => {
  const value = get(item, 'value') || '';
  return ({
    title: langStrings(get(item, 'title')),
    position: get(item, 'position') || 0,
    key: get(item, 'key') || '',
    value,
    hasOption: !(!!value),
    option: langStrings(get(item, 'option')),
    duplicableValues: (get(item, 'duplicable_values') || []).map(item => duplicableValue(item)),
    type: get(item, 'type')
  });
};

export const duplicableValue = (item: Types.IApi.ApplicationSingle.DuplicableValue): Types.IEntity.DuplicableValue => {
  const value = get(item, 'value') || '';
  return {
    position: get(item, 'position') || 0,
    value,
    hasOption: !(!!value),
    option: langStrings(get(item, 'option'))
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? ((get(item, 'currentPage') || 0) + 1) : 1,
    total: get(item, 'totalPages') || 1
  };
};