import moment from 'moment';

import get from 'lodash/get';
import groupBy from 'lodash/groupBy';

import * as Types from './types';
import orderBy from 'lodash/orderBy';

export const certificate = (item: Types.IApi.Certificate.Response): Types.IEntity.Certificate => {
  const specializations = (get(item, `specializations`) || []).map(s => ({
    id: get(s, 'id') || 0,
    position: get(s, 'position') || 0,
    name: langStrings(get(s, 'name')),
    description: langStrings(get(s, 'description'))
  }));

  const specializationsByPosition = groupBy(specializations, 'position');

  const specializationsByAddress = (get(item, 'activity_addresses') || []).map(item => ({
    name: langStrings(get(item, 'value')),
    specializations: specializationsByPosition[get(item, 'position') || 0] || []
  }));

  return ({
    id: get(item, 'id') || 0,
    uuid: get(item, 'uuid') || "",
    tin: get(item, 'tin') ? `${get(item, 'tin')}` : '',
    pin: get(item, 'pin') || '',
    document_id: get(item, 'document_id') || '',
    owner_type: get(item, 'owner_type') || '',
    name: get(item, 'name') || '',
    number: get(item, 'number') || '',
    branch: get(item, 'branch') || false,
    organization: langStrings(get(item, 'organization')),
    category: {
      title: langStrings(get(item, 'category.title')),
      image: get(item, 'category.icon_id') || '',
      gradient: {
        start: get(item, 'category.gradient_start_color') || '',
        end: get(item, 'category.gradient_end_color') || ''
      }
    },
    status: {
      key: get(item, 'status.status') || '',
      color: get(item, 'status.background') || '',
      title: langStrings(get(item, 'status.title'))
    },
    applications: (get(item, 'applications') || []).map(item => ({
      id: get(item, 'id') || '',
      type: {
        key: get(item, 'application_type.key'),
        title: langStrings(get(item, 'application_type.title'))
      },
      date: get(item, 'created_at') || '',
      status: {
        key: get(item, 'status.key') || '',
        title: langStrings(get(item, 'status.title'))
      }
    })),
    document: langStrings(get(item, 'document')),
    type: {
      key: get(item, 'type') || '',
      title: langStrings(get(item, 'type_title'))
    },
    address: get(item, 'address') || '',
    region: get(item, 'region') || '',
    subRegion: get(item, 'subRegion') || '',
    village: get(item, 'village') || '',
    registration: {
      serial: get(item, 'serial') || '',
      number: get(item, 'register_number') || '',
      date: get(item, 'registration_date') || ''
    },
    expires: get(item, 'expiry_date') || '',
    isActive: !!get(item, 'active'),
    link: get(item, 'uuid') || '',
    specializations,
    specializationsByAddress,
    is_multi_specialization: !!get(item, 'is_multi_specialization'),
    training_categories: get(item, 'training_categories') ? (get(item, 'training_categories') || []).map(category => ({
      name: get(category, `name`) || '',
      courses: (get(category, 'courses') || []).map(course => get(course, `name`) || '')
    })) : [],
    activityAddresses: (get(item, 'activity_addresses') || []).map(item => langStrings(get(item, 'value'))),
    payments: (get(item, 'payments') || []).map(item => payment(item)),
    fees: (get(item, 'fees') || []).map(item => fees(item)),
    hasUsageFee: !!get(item, 'usage_fee'),
    feeDate: {
      begin: get(item, 'fees_period_begin') || '',
      end: get(item, 'fees_period_end') || ''
    },
    brand_mark: get(item, 'brand_mark') || '',
    isArchive: !!get(item, 'import_register'),
    additions: additions(get(item, 'additions'))
  });
}

export const payment = (item: Types.IApi.Certificate.Payment): Types.IEntity.Payment => {
  return {
    number: get(item, 'serial') || '',
    amount: get(item, 'amount') || '',
    date: get(item, 'issue_date') || '',
    status: get(item, 'status') || '',
    application: {
      id: get(item, 'application_id') || '',
      type: langStrings(get(item, 'application_type')) || ''
    }
  };
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const report = (item?: Types.IApi.Report | null): Types.IEntity.Report => {
  item = item || {};
  return {
    created_by: get(item, 'created_by') || 0,
    created_date: get(item, 'created_date') ? moment(get(item, 'created_date')).format('DD.MM.YYYY') : '',
    document_id: get(item, 'document_id') || 0,
    id: get(item, 'id') || 0,
    organization_id: get(item, 'organization_id') || 0,
    register_id: get(item, 'register_id') || 0,
    report_file: {
      download_uri: get(item, 'report_file.download_uri') || '',
      extension: get(item, 'report_file.extension') || '',
      fileId: get(item, 'report_file.fileId') || 0,
      name: get(item, 'report_file.name') || '',
      size: formatBytes(get(item, 'report_file.size') || 0)
    },
    report_file_id: get(item, 'report_file_id') || 0,
    status: get(item, 'status') || 0
  };
};

export const fees = (item: Types.IApi.Certificate.Payment): Types.IEntity.Fee => {
  return {
    number: get(item, 'serial') || '',
    amount: get(item, 'amount') || '',
    date: get(item, 'issue_date') || '',
    begin: get(item, 'fees_period_begin') || '',
    end: get(item, 'fees_period_end') || '',
    status: get(item, 'status') || '',
    application: {
      id: get(item, 'application_id') || '',
      type: langStrings(get(item, 'application_type')) || ''
    }
  };
};

export const langStrings = (item?: Types.IApi.LangStrings | null): Types.IEntity.LangStrings => {
  item = item || {};

  return {
    uz: get(item, 'uz', '') || '',
    oz: get(item, 'oz', '') || '',
    ru: get(item, 'ru', '') || '',
    en: get(item, 'en', '') || ''
  };
};

export const meta = (item?: Types.IApi.Meta | null): Types.IEntity.Meta => {
  return {
    current: get(item, 'currentPage') ? (get(item, 'currentPage') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};

export const reportMeta = (item?: Types.IApi.ReportMeta | null): Types.IEntity.ReportMeta => {
  return {
    current: get(item, 'number') ? (get(item, 'number') || 0) + 1 : 1,
    total: get(item, 'totalPages') || 1
  };
};

export const additions = (item) => {
  return (
    orderBy((item || []).map(item => {
      let value = get(item, 'value') || '';

      return ({
        key: get(item, 'key') || '',
        type: get(item, 'type') || '',
        title: get(item, `title`) || '',
        position: get(item, `position`) || '',
        value,
        duplicable_values: groupBy((get(item, 'duplicable_values') || []).map(item => {
          return {
            row: get(item, 'position'),
            position: get(item, 'row'),
            value: get(item, 'value') || '',
            option: get(item, `option`) || '',
            isHead: Number(get(item, 'position')) === 0
          }
        }), 'row')
      })
    }), ['position'], 'asc')
  )
}
