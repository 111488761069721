import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Types from '../types';

interface IChildren {
  items: Types.IEntity.Workers[];
  isFetched: boolean;
  isLoaded: boolean;
}

interface IProps {
  id: number;
  uuid: string;
  params?: Partial<Types.IBranchParams>;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
}

const useWorkers = ({
  id,
  uuid,
  onSuccess = () => { },
  onError = () => { },
  onFinally = () => { } }: IProps): IChildren => {
  const dispatch = useDispatch();

  const items = useSelector<Store.Types.IState, Types.IEntity.Workers[]>(state => state.branches.open_workers.items);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.branches.open_workers.isFetched);
  const isLoaded = useSelector<Store.Types.IState, boolean>(state => state.branches.open_workers.isLoaded);

  useEffect(() => {
    dispatch(Actions.OpenWorkers.request({
      id,
      uuid,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  }, []);

  return {
    items,
    isFetched,
    isLoaded
  };
};

export default useWorkers;