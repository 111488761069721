import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import * as Store from 'store';

import * as Actions from '../actions';
import * as Constants from '../constants';
import * as Types from '../types';

interface IChildren {
  process: Types.IEntity.Process;
  info: Types.IEntity.Info;
  isFetched: boolean;
  error: string;
  methods: {
    start: (documentId: number, applicantType?: Constants.APPLICANT_TYPE) => void;
    resume: (applicationId: number) => void;
  }
}

interface IProps {
  resumeOnload?: boolean;
  applicationId?: number | string;
  onSuccess?: ({ process }: { process: Types.IEntity.Process }) => void;
  onError?: (error: string) => void;
  onFinally?: () => void;
}

let cancelSource = axios.CancelToken.source();

const useProcess = ({ resumeOnload, applicationId, onSuccess = () => { }, onError = () => { }, onFinally = () => { } }: IProps = {}): IChildren => {
  const dispatch = useDispatch();

  const process = useSelector<Store.Types.IState, Types.IEntity.Process>(state => state.process.process);
  const info = useSelector<Store.Types.IState, Types.IEntity.Info>(state => state.process.info);
  const isFetched = useSelector<Store.Types.IState, boolean>(state => state.process.isFetched);
  const error = useSelector<Store.Types.IState, string>(state => state.process.error);

  useEffect(() => {
    resumeOnload && applicationId && resume(Number(applicationId));
  }, []);

  useEffect(() => () => cancelSource.cancel('canceled'), []);

  const start = (documentId: number, applicantType?: Constants.APPLICANT_TYPE) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.Start.request({
      documentId,
      applicantType,
      cancelSource,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  };

  const resume = (applicationId: number) => {
    cancelSource = axios.CancelToken.source();
    dispatch(Actions.Resume.request({
      applicationId,
      cancelSource,
      callback: {
        onSuccess,
        onError,
        onFinally
      }
    }));
  };

  return { process, info, isFetched, error, methods: { start, resume } };
};

export default useProcess;
