import { AxiosPromise } from 'axios';
import { http } from 'services';

import * as Types from './types';
import config from 'config';

export const Start = ({
  documentId,
  applicantType
}: Types.IApi.Start.Request): AxiosPromise<Types.IApi.Start.Request> => {
  return http.request.post<Types.IApi.Start.Request>(
    `/process/start`,
    {},
    {
      baseURL: config.api.base_url_v2,
      params: {
        document_id: documentId,
        applicant_type: applicantType
      }
    }
  );
};

export const StartByRegister = ({
  registerId,
  type
}: Types.IApi.StartByRegister.Request): AxiosPromise<Types.IApi.Start.Request> => {
  return http.request.post<Types.IApi.Start.Request>(
    `/process/start?register_id=${registerId}&application_type=${type}`,
    {},
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const StartByBranch = ({
  registerId,
  id,
  type
}: Types.IApi.StartByBranch.Request): AxiosPromise<Types.IApi.Start.Request> => {
  return http.request.post<Types.IApi.Start.Request>(
    `/process/start`,
    {},
    {
      baseURL: config.api.base_url_v2,
      params: {
        branch_id: id ? id : undefined,
        register_id: registerId,
        branch_type: type,
        application_type: 'MODIFICATION'
      }
    }
  );
};

export const StartByBranchSingle = ({
  registerId,
  type,
  id
}: Types.IApi.StartByBranchSingle.Request): AxiosPromise<Types.IApi.Start.Request> => {
  return http.request.post<Types.IApi.Start.Request>(
    `/process/start?register_id=${registerId}&branch_type=${type}&branch_id=${id}&application_type=MODIFICATION`,
    {},
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const Update = ({
  applicationId,
  stepId,
  position,
  values
}: Types.IApi.Update.Request): AxiosPromise<Types.IApi.Update.Response> => {
  const newValues = Object.values(values).map(item => ({
    key: item.key,
    value: item.value,
    duplicable_values: (item.duplicable_values || []).map(item => ({
      position: item.position,
      value: item.value,
      duplicable_values: item.duplicable_values
    }))
  }));
  return http.request.post<Types.IApi.Update.Response>(
    `/process/update?application_id=${applicationId}&step_id=${position}`,
    newValues,
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const Back = ({
  applicationId,
  stepId,
  position,
  changePosition
}: Types.IApi.Back.Request): AxiosPromise<Types.IApi.Update.Response> => {
  return http.request.post<Types.IApi.Update.Response>(
    `/process/update?application_id=${applicationId}&step_id=${position}&change_step_id=${changePosition}`,
    null,
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const Resume = ({ applicationId }: Types.IApi.Resume.Request): AxiosPromise<Types.IApi.Resume.Response> => {
  return http.request.post<Types.IApi.Resume.Response>(
    `/process/resume?application_id=${applicationId}`,
    {},
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const Cancel = ({ applicationId }: Types.IApi.Resume.Request): AxiosPromise<Types.IApi.Resume.Response> => {
  return http.request.post<Types.IApi.Resume.Response>(
    `/process/cancel?application_id=${applicationId}`,
    {},
    {
      baseURL: config.api.base_url_v2
    }
  );
};

export const Info = ({
  documentId,
  applicationId
}: {
  documentId: number;
  applicationId: number;
}): AxiosPromise<Types.IApi.Info.Response> => {
  return http.request.get<Types.IApi.Info.Response>(
    `/reference/documents/process?document_id=${documentId}&application_id=${applicationId}`
  );
};

export const Specialization = ({
  documentId
}: Types.IApi.Specialization.Request): AxiosPromise<Types.IApi.Specialization.Response> => {
  return http.request.get<Types.IApi.Specialization.Response>(
    `/reference/documents/specialization?document_id=${documentId}`
  );
};

export const Requirements = ({
  documentId,
  specializationIds = []
}: Types.IApi.Requirements.Request): AxiosPromise<Types.IApi.Specialization.Response> => {
  return http.request.get<Types.IApi.Specialization.Response>(
    `/reference/documents/requirement?document_id=${documentId}&SELECTED_SPECIALIZATIONS=${specializationIds.join(',')}`
  );
};

export const ActionEvent = ({
  applicationId,
  key,
  fields,
  language
}: Types.IApi.ActionEvent.Request): AxiosPromise<Types.IApi.Specialization.Response> => {
  return http.request.post<Types.IApi.Specialization.Response>(
    `action?application_id=${applicationId}&lang=${language}&action_key=${key}`,
    fields
  );
};

export const FileUpload = ({
  applicationId,
  data
}: Types.IApi.FileUpload.Request): AxiosPromise<Types.IApi.FileUpload.Response> => {
  return http.request.post<Types.IApi.FileUpload.Response>(
    `/file/application/upload?application_id=${applicationId}`,
    data
  );
};

export const OpenFileUpload = ({
  applicationId,
  data
}: Types.IApi.OpenFileUpload.Request): AxiosPromise<Types.IApi.OpenFileUpload.Response> => {
  return http.request.post<Types.IApi.OpenFileUpload.Response>(
    `/file/open_source/upload?application_id=${applicationId}`,
    data
  );
};

export const FileInfo = ({
  id,
  applicationId
}: Types.IApi.FileInfo.Request): AxiosPromise<Types.IApi.FileInfo.Response> => {
  return http.request.get<Types.IApi.FileInfo.Response>(`/file/application/info/${id}?application_id=${applicationId}`);
};

export const FileDownload = ({ id, applicationId }: Types.IApi.FileDownload.Request): AxiosPromise => {
  return http.request.get(`/file/application/download/${id}?application_id=${applicationId}`, { responseType: 'blob' });
};
