import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

import { Hooks } from 'modules/process'
import { useRegisterRef } from 'modules/registry/hooks'
import React, { useMemo, useState } from 'react'
import * as ProcessModule from 'modules/process';
import Button from 'components/Button';
import * as Icon from 'components/Icon';

import Payment from './Payment';
import Spinner from 'components/Spinner';

interface Props {
    registerId: number,
    licenseId: string,
    feeDateEnd: string
}
const DynamicProcessButtons = ({ registerId, licenseId, feeDateEnd }: Props) => {
    const { t, i18n } = useTranslation()
    const { item, isFetched } = useRegisterRef({ registerId })
    const Process = Hooks.useProcessStart({ registerId });
    const [isLoading, setLoading] = useState<string | null>(null);
    const PROCESS_TYPES = ProcessModule.Constants.TYPE;
    const [isLoadingBranch, setBranchLoading] = useState(false);

    function onError(error: any) {
        const message = get(error, `message.title[${i18n.language}]`) || '';
        const type = get(error, `message.type`) || '';
        !!message && (type === 'INFO' ? toast(message) : toast.error(message));
    }

    const onClick = (type: ProcessModule.Constants.TYPE) => {
        if (!isLoading) {
            setLoading(type);
            Process.start(type, {
                onError,
                onFinally: () => {
                    setLoading(null);
                }
            });
        }
    };

    const onClickBranch = () => {
        if (!isLoadingBranch) {
            setBranchLoading(true);
            Process.branch(PROCESS_TYPES.REGISTRATION, {
                onError,
                onFinally: () => setBranchLoading(false)
            });
        }
    };


    const renderButtons = useMemo(() => ([
        {
            key: PROCESS_TYPES.SUSPEND_TO_ACTIVE,
            button: (
                <Button
                    className="m-r-12"
                    variant="green"
                    prefixIcon={<Icon.System.Check />}
                    disabled={isLoading === PROCESS_TYPES.SUSPEND_TO_ACTIVE}
                    isLoading={isLoading === PROCESS_TYPES.SUSPEND_TO_ACTIVE}
                    onClick={() => onClick(PROCESS_TYPES.SUSPEND_TO_ACTIVE)}
                >
                    {t('suspend_to_active')}
                </Button>
            ),
        },
        {
            key: PROCESS_TYPES.CHANGE,
            button: (
                <Button
                    className="m-r-12"
                    prefixIcon={<Icon.System.Edit />}
                    disabled={isLoading === PROCESS_TYPES.CHANGE}
                    isLoading={isLoading === PROCESS_TYPES.CHANGE}
                    onClick={() => onClick(PROCESS_TYPES.CHANGE)}
                >
                    {t('Renewal')}
                </Button>
            ),
        },
        {
            key: PROCESS_TYPES.MODIFICATION,
            button: (
                <Button
                    className="m-r-12"
                    prefixIcon={<Icon.System.Edit />}
                    disabled={isLoading === PROCESS_TYPES.MODIFICATION}
                    isLoading={isLoading === PROCESS_TYPES.MODIFICATION}
                    onClick={() => onClick(PROCESS_TYPES.MODIFICATION)}
                >
                    {t('Modification')}
                </Button>
            ),
        },
        {
            key: PROCESS_TYPES.EXTENSION,
            button: (
                <Button
                    className="m-r-12"
                    prefixIcon={<Icon.System.Time />}
                    disabled={isLoading === PROCESS_TYPES.EXTENSION}
                    isLoading={isLoading === PROCESS_TYPES.EXTENSION}
                    onClick={() => onClick(PROCESS_TYPES.EXTENSION)}
                >
                    {t('extend_the_deadline')}
                </Button>
            ),
        },
        {
            key: PROCESS_TYPES.SUSPEND,
            button: (
                <Button
                    variant="red"
                    className="m-r-12"
                    prefixIcon={<Icon.System.Cross />}
                    disabled={isLoading === PROCESS_TYPES.SUSPEND}
                    isLoading={isLoading === PROCESS_TYPES.SUSPEND}
                    onClick={() => onClick(PROCESS_TYPES.SUSPEND)}
                >
                    {t('Suspension')}
                </Button>
            ),
        },
        {
            key: PROCESS_TYPES.TERMINATION,
            button: (
                <Button
                    variant="red"
                    className="m-r-12"
                    prefixIcon={<Icon.System.Cross />}
                    disabled={isLoading === PROCESS_TYPES.TERMINATION}
                    isLoading={isLoading === PROCESS_TYPES.TERMINATION}
                    onClick={() => onClick(PROCESS_TYPES.TERMINATION)}
                >
                    {t('Termination')}
                </Button>
            ),
        }
    ]), [isFetched])

    if(!isFetched){
        return <Spinner />
    }

    return (
        <>
            {item.fees && <Payment id={registerId} licenseId={licenseId} feeDateEnd={feeDateEnd} />}
            {renderButtons.map(b => {
                if (!item.applicationTypes.includes(b.key)) {
                    return null
                }
                return b.button
            })}
            {item.branch && (
                <Button
                    variant="green"
                    className="m-r-12"
                    prefixIcon={<Icon.System.Plus />}
                    disabled={isLoadingBranch}
                    isLoading={isLoadingBranch}
                    onClick={onClickBranch}
                >
                    {t('add_branche')}
                </Button>
            )}
        </>
    )
}

export default DynamicProcessButtons