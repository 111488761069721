import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import truncate from 'lodash/truncate';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router';

import * as AuthModule from 'modules/auth';
import * as CategoryModule from 'modules/category';
import * as DocumentModule from 'modules/document';
import * as ProcessModule from 'modules/process';
import * as StepperModule from 'modules/stepper';
import * as SystemModule from 'modules/system';

import * as Icon from 'components/Icon';
import InfoBlock from 'components/InfoBlock';
import Button from 'components/Button';
import Modal, { Nav } from 'components/Modal';
import * as Breadcrumb from 'components/Breadcrumb';

import DocumentInfo from './components/DocumentInfo';
import ApplicantType from './components/ApplicantType';

import * as Hooks from '../../../hooks';

import classes from './DocumentView.module.scss';
import StatCard from '../../StatCard';

const DocumentView: React.FC = () => {
  const location = useLocation()
  const { isAuthenticated, profile } = AuthModule.Hooks.useAuth();
  const { language } = SystemModule.Hooks.useLanguage();
  const { t } = useTranslation();
  const history = useHistory();
  const stepper = StepperModule.useContext();

  const { state: { categoryId, subCategoryId, documentId }, methods: { setClose } } = CategoryModule.Context.Single.useContext();
  const category = Hooks.useSingle({ id: categoryId });
  const subCategory = Hooks.useSingle({ id: subCategoryId });

  const document = DocumentModule.Hooks.useSingle({ id: documentId });
  const { methods } = ProcessModule.Hooks.useProcess({
    onSuccess({ process }) {
      if (location.pathname.startsWith('/kiosk')) {
        history.push(`/kiosk/application/${process.applicationId}`)
      } else {
        history.push(`/application/${process.applicationId}`)
      }
    },
    onError: (error) => {
      setStarting(false);
      const message = get(error, `message.title[${language}]`) || '';
      const type = get(error, `message.type`) || '';
      !!message && (type === 'INFO' ? toast(message) : toast.error(message));
    }
  });
  const Info = DocumentModule.Hooks.useInfo({ documentId });
  const [isStarting, setStarting] = useState(false);
  const [isApplicantType, setApplicantType] = useState(false);

  const onStart = (id: number) => {
    if (isAuthenticated) {
      if (profile.type === AuthModule.Constants.TYPE.EMAIL) {
        setApplicantType(true);
      } else {
        methods.start(id);
        setStarting(true);
      }
    } else {
      history.push('/auth');
    }
  };

  useEffect(() => {
    const modal = window.document.querySelector('[class^="Modal_wrapper"]');
    modal && modal.scrollTo && modal.scrollTo(0, 0);
  }, []);

  let title = get(category, `title[${language}]`);
  if (category.children.length) {
    title = get(subCategory, `title[${language}]`);
  }

  const serviceCost = get(Info, 'item.priceBegin') ?
    t('license_price_min_max', { min: get(Info, 'item.priceBegin'), max: get(Info, 'item.price') }) :
    (get(Info, 'item.price') ? t('license_price', { value: get(Info, 'item.price') }) : t('free'))



  const workingDays = get(Info, 'item.review.timeBegin') ?
    t(get(Info, 'item.review.isWeekDays') ? 'up_to_working_days_min_max' : 'up_to_working_days_min_max_is_week', { min: get(Info, 'item.review.timeBegin'), max: get(Info, 'item.review.time') }) :
    get(Info, 'item.review.time') ? (t(get(Info, 'item.review.isWeekDays') ? 'up_to_working_days' : 'up_to_days', { value: get(Info, 'item.review.time') })) : t('document_online_fast')

  return (
    <>
      <div className={classes.wrapper}>
        <Nav showClose onClose={setClose} showPrev onPrev={() => stepper.prev()} />
        <div className={classes.breadcrumb}>
          <Breadcrumb.List variant="dark">
            <Breadcrumb.Item onClick={() => stepper.goTo(!category.children.length ? 'documents' : 'subCategory')}>
              {truncate(get(category, `title[${language}]`), { length: 40 })}
            </Breadcrumb.Item>
            {!!category.children.length && (
              <Breadcrumb.Item onClick={() => stepper.prev()}>{truncate(title, { length: 40 })}</Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{truncate(get(document, `title[${language}]`), { length: 40 })}</Breadcrumb.Item>
          </Breadcrumb.List>
        </div>
        <div className={classes.title}>{get(document, `title[${language}]`)}</div>
        <div className={classes.info}>
          <div className={classes.infoInner}>
            <Button
              size="large"
              variant="green"
              prefixIcon={<Icon.System.Plus />}
              onClick={() => onStart(document.id)}
              isLoading={isStarting}
              disabled={isStarting}
              className="m-r-12"
            >
              {t('submit_application')}
            </Button>
            <InfoBlock variant="white" title={t('Document_type', { defaultValue: 'Ҳужжат тури' })} children={get(document, `type.title[${language}]`)} className="m-r-12" />
            <InfoBlock
              variant="white"
              title={t('service_price', { defaultValue: 'Хизмат нархи' })}
              children={serviceCost}
              className="m-r-12"
            />
            {!!get(Info, 'item.duration') && (
              <InfoBlock
                variant="white"
                title={t('License_term', { defaultValue: 'Лицензия муддати' })}
                children={(get(Info, 'item.expiry') ?
                  `${get(Info, 'item.duration')} ${t(get(Info, 'item.expiry'))}` :
                  t('license_duration', { count: get(Info, 'item.duration') as any }))}
                className="m-r-12"
              />
            )}
            <InfoBlock
              variant="white"
              // title={t(`${get(document, 'type.key')}_issuance_period`)}
              title={t("LICENSE_issuance_period")}
              children={workingDays}
            />
          </div>
        </div>
        <div className={classes.content}>
          <DocumentInfo documentId={document.id} {...Info} />
          <StatCard document_id={document.id} />
        </div>
      </div>

      <Modal isOpen={isApplicantType} close={() => setApplicantType(false)} width={600}>
        <ApplicantType documentId={document.id} close={() => setApplicantType(false)} />
      </Modal>
    </>
  );
};

export default DocumentView;
